import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ForecastingStockService {
    readForcastingStockQuery(){
        var query = `
            query( $id : String!, $paging:ServerPaging){
                GetItemStockAdvanced (StorageId:$id, Paging:$paging) {
                    item_stock{
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        uom
                        conv_amount
                        base_stock_gudang
                        secondary_stock_gudang
                        base_stock_sales
                        secondary_stock_sales
                        minimal_stock
                        secondary_minimal_stock
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var str = { 
                value: result.data.GetStorage[i].storage_id, 
                label: result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            }
            storageData.push(str);
        }
        return storageData
    }

    async getForcastingStockQueryByStorageId(variables){
        var query = gql`
            query( $id : String! ){
                GetItemStockAdvanced (StorageId:$id) {
                    item_stock{
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        uom
                        conv_amount
                        base_stock_gudang
                        secondary_stock_gudang
                        base_stock_sales
                        secondary_stock_sales
                        minimal_stock
                        secondary_minimal_stock
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetItemStockAdvanced.item_stock;
    }
    
    ItemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    storage_name: data[i].storage_name,
                    product_code: data[i].product_code,                    
                    item_name: data[i].item_name,
                    barcode: data[i].barcode,
                    type_detail_name: data[i].type_detail_name,
                    base_uom: data[i].base_uom,
                    uom: data[i].uom,
                    conv_amount: data[i].conv_amount,
                    base_stock_gudang: data[i].base_stock_gudang,
                    secondary_stock_gudang: data[i].secondary_stock_gudang,
                    base_stock_sales: data[i].base_stock_sales,
                    secondary_stock_sales: data[i].secondary_stock_sales,
                    minimal_stock: data[i].minimal_stock,
                    secondary_minimal_stock: data[i].secondary_minimal_stock
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }
}

export default new ForecastingStockService();